import React from "react";
import {v4 as uuidv4} from "uuid";

export type ConfigType = {
    apiKey: string | null,
    model: 'gpt-4' | 'gpt-4-turbo',
    conversationId: string,
}

const defaultConfig: ConfigType = {
    apiKey: null,
    model: 'gpt-4',
    conversationId: uuidv4(),
}

const getFromStorage = () => {
    const config = localStorage.getItem('config');
    if (config) {
        return JSON.parse(config) as ConfigType;
    }
    return defaultConfig;
}

const setToStorage = (config: ConfigType) => {
    localStorage.setItem('config', JSON.stringify(config));
}

const ConfigContext = React.createContext<{
    config: ConfigType
    setConfig: (config: ConfigType) => void
}>({
    config: getFromStorage(),
    setConfig: (config: ConfigType) => {}
})

export const useConfig = () => {
    return React.useContext(ConfigContext);
}

export const ConfigProvider = ({ children }: any) => {
    const [config, setConfig] = React.useState<ConfigType>(getFromStorage());

    const saveConfig = (config: ConfigType) => {
       setConfig(config);
       setToStorage(config);
    };

    return (
        <ConfigContext.Provider value={{ config, setConfig: saveConfig }}>
            {children}
        </ConfigContext.Provider>
    );
}
