import React from 'react';

type HistoryItemProps = {
    who: string;
    what: string;
    when: string;
}

export default function HistoryItemComponent(props: HistoryItemProps) {
    const { who, what, when } = props;
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
            borderBottom: '1px solid #333',
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <div style={{ fontSize: '0.8em' }}>{who}</div>
                <div style={{ fontSize: '0.8em' }}>{when}</div>
            </div>
            <div style={{ fontSize: '1em' }}>{what}</div>
        </div>
    );
}
